<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$store.getters.theme'(newVal, oldVal) {
      this.switchTheme(newVal, oldVal)
    },
  },
  mounted() {
    document.documentElement.classList.add(this.$store.getters.theme)
  },
  methods: {
    switchTheme(theme, oldTheme) {
      oldTheme && document.documentElement.classList.remove(oldTheme)
      document.documentElement.classList.add(theme)
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: Montserrat;
  src: url('./assets/Montserrat-Regular.ttf');
}

// body.lang-en * {
//   font-family: Montserrat, 'Microsoft Yahei', Tahoma, Arial, Helvetica, STHeiti;
// }
body {
  font-family: Montserrat, 'Microsoft Yahei', Tahoma, Arial, Helvetica, STHeiti;
  span {
    font-family: Montserrat, 'Microsoft Yahei', Tahoma, Arial, Helvetica, STHeiti;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  color: $--color-text-primary;
  font-size: $--size-base;
}

img {
  max-width: 100%;
  max-height: 100%;
}

// el-icon
[class^='el-icon-'],
[class*=' el-icon-'] {
  font-size: $--font-size-medium;
}

.el-icon-question {
  font-size: $--font-size-base;
}

// el-button
.el-button {
  @mixin btn {
    height: 40px;
    line-height: 40px;
    padding: 0 16px !important;
  }
  &.el-button--default {
    @include btn;
    @include setBgColor('btnBgColor');
    @include setTextColor('btnTextColor');
    @include setBorderColor('btnBorderColor');
    &:hover,
    &:focus {
      @include setBgColor('btnHoverBgColor');
      @include setTextColor('btnHoverTextColor');
      @include setBorderColor('btnHoverBorderColor');
    }
    &:disabled:hover {
      @include setBgColor('btnBgColor');
      @include setTextColor('btnTextColor');
      @include setBorderColor('btnBorderColor');
    }
  }
  &.el-button--primary {
    @include btn;
    @include setBgColor('btnPrimaryBgColor');
    @include setTextColor('btnPrimaryTextColor');
    @include setBorderColor('btnPrimaryBorderColor');
    &:hover,
    &:focus {
      @include setBgColor('btnHoverBgColor');
      @include setTextColor('btnHoverTextColor');
      @include setBorderColor('btnHoverBorderColor');
    }
    &:disabled:hover {
      @include setBgColor('btnPrimaryBgColor');
      @include setTextColor('btnPrimaryTextColor');
      @include setBorderColor('btnPrimaryBorderColor');
    }
  }
  &.el-button--secondary {
    @include btn;
    @include setBgColor('btnSecondaryBgColor');
    @include setTextColor('btnSecondaryTextColor');
    @include setBorderColor('btnSecondaryBorderColor');
    &:hover,
    &:focus {
      @include setBgColor('btnHoverBgColor');
      @include setTextColor('btnHoverTextColor');
      @include setBorderColor('btnHoverBorderColor');
    }
    &:disabled:hover {
      @include setBgColor('btnSecondaryBgColor');
      @include setTextColor('btnSecondaryTextColor');
      @include setBorderColor('btnSecondaryBorderColor');
    }
  }
  &.el-button--tertiary {
    @include btn;
    @include setBgColor('btnTertiaryBgColor');
    @include setTextColor('btnTertiaryTextColor');
    @include setBorderColor('btnTertiaryBorderColor');
    &:hover,
    &:focus {
      @include setBgColor('btnHoverBgColor');
      @include setTextColor('btnHoverTextColor');
      @include setBorderColor('btnHoverBorderColor');
    }
    &:disabled:hover {
      @include setBgColor('btnTertiaryBgColor');
      @include setTextColor('btnTertiaryTextColor');
      @include setBorderColor('btnTertiaryBorderColor');
    }
  }
  &.el-button--text {
    &:hover,
    &:focus {
      @include setTextColor('btnHoverBgColor');
      border-color: transparent;
      background-color: transparent;
    }
  }
  &.el-button--micro {
    width: 16px;
    height: 16px;
    font-size: 8px !important;
    line-height: 1 !important;
    padding: 0 !important;
    i {
      font-size: 8px;
    }
  }
  &.el-button--mini {
    height: auto;
    line-height: 1;
    padding: 4px !important;
  }
  &.el-button--small {
    @include setBgColor('btnBgColor');
    @include setTextColor('btnTextColor');
    @include setBorderColor('btnBorderColor');
    height: auto;
    padding: 5px 10px !important;
    .el-message-box__btns & {
      height: 40px;
      line-height: 40px;
      padding: 0 15px !important;
    }
    &:hover,
    &:focus {
      @include setBgColor('btnHoverBgColor');
      @include setTextColor('btnHoverTextColor');
      @include setBorderColor('btnHoverBorderColor');
    }
  }
  &.el-button--medium {
    height: 36px;
    line-height: 36px;
  }
}

.el-footer {
  > .el-button {
    min-width: 120px;
  }
}

// el-tooltip
.el-tooltip__popper {
  max-width: 300px;
}

// el-drawer
.el-drawer {
  width: 40% !important;
}

.el-drawer__container .el-drawer__header {
  position: relative;
  font-size: $--font-size-extra-large;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-weight: 600;

  & > span {
    color: $--color-text-primary;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid $--border-color-content;
    content: '';
  }
}

// el-table
.el-table::before {
  height: 0 !important;
}

.el-table {
  border-radius: 5px 5px 0 0;
  .el-table__row:has(.is-checked) .el-table__cell {
    background-color: #fafafa;
  }
}

.el-table thead th.el-table__cell {
  @include setBgColor('theadBgColor');
  @include setTextColor('theadTextColor');
  font-weight: 600;
}

.el-table .el-table__cell .el-button--text {
  font-size: $--font-size-medium;
  color: $--icon-color;
}

// .el-table.has-bottom-border {
//   border-radius: 6px;
//   .el-table__empty-block {
//     border-bottom: 1px solid $--border-color-content;
//     border-radius: 0 0 6px 6px;
//   }
//   .el-table__header {
//     thead {
//       .el-table__cell:first-child {
//         border-radius: 6px 0 0 0;
//       }
//       .el-table__cell:has(+ .gutter) {
//         border-radius: 0 6px 0 0;
//       }
//     }
//   }
//   .el-table__body {
//     .el-table__row:last-child {
//       td:first-child {
//         border-radius: 0 0 0 6px;
//       }
//       td:last-child {
//         border-radius: 0 0 6px 0;
//       }
//     }
//   }
// }

// text-line
.text-line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-line-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// el-dialog
.el-dialog__wrapper .el-dialog__header {
  position: relative;
  font-size: $--font-size-extra-large;
  margin-bottom: 0;
  padding-bottom: 20px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid $--border-color-content;
    content: '';
  }
}

.el-dialog__headerbtn .el-dialog__close.el-icon-close {
  color: $--icon-color;
}

// v-modal
.v-modal {
  background: rgba(35, 35, 60, 0.08) !important;
  opacity: 1 !important;
}

// el-message-box
// type:center
.el-message-box__wrapper {
  & + .v-modal {
    background: rgba(35, 35, 60, 0.08);
    opacity: 1;
  }

  .el-message-box.el-message-box--center {
    box-shadow: 0px 0px 10px 0px rgba(35, 35, 60, 0.25);

    .el-message-box__headerbtn {
      display: none;
    }

    .el-message-box__title {
      display: block;
      text-align: center;

      .el-message-box__status {
        display: block;
        padding: 0;
        font-size: 70px !important;
        margin-bottom: 20px;
      }

      & > span {
        font-weight: 700;
      }
    }

    .el-message-box__message > p {
      color: #000000;
      margin-bottom: 20px;
    }

    .el-message-box__btns {
      .el-button {
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
        &.el-button--primary {
          @include setBgColor('btnPrimaryBgColor');
          @include setTextColor('btnPrimaryTextColor');
          @include setBorderColor('btnPrimaryBorderColor');
          &:hover,
          &:focus:hover {
            @include setBgColor('btnHoverBgColor');
            @include setTextColor('btnHoverTextColor');
            @include setBorderColor('btnHoverBorderColor');
          }
          &:focus,
          &:disabled:hover {
            @include setBgColor('btnPrimaryBgColor');
            @include setTextColor('btnPrimaryTextColor');
            @include setBorderColor('btnPrimaryBorderColor');
          }
        }
      }
    }
  }
}

// el-scrollbar
.el-scrollbar {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .is-horizontal {
    display: none;
  }
}

// element 下拉bug
.el-autocomplete-suggestion__wrap.el-scrollbar__wrap,
.el-select-dropdown__wrap.el-scrollbar__wrap,
.el-cascader-menu__wrap.el-scrollbar__wrap,
.time-select .el-picker-panel__content {
  margin-bottom: 0 !important;
}

// el-input
.el-input .el-input__inner[type='number'] {
  padding-right: 0;
}
.el-input .el-input-group__append {
  background: #fff;
  color: $--icon-color;
}

// form
.el-form .el-form-item__label {
  color: $--color-text-primary;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.el-form-item__error {
  word-break: normal;
  overflow-wrap: break-word;
}

// el-select
.el-select .el-input .el-icon-arrow-up {
  font-family: 'element-icons' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $--icon-color !important;

  &::before {
    content: '\e64b';
  }
}

.el-select .el-input .el-select__caret {
  transform: rotateZ(0deg) !important;

  &.is-reverse {
    transform: rotateZ(180deg) !important;
  }
}

.el-select-dropdown .el-select-dropdown__list .el-select-dropdown__item,
.el-cascader-menu__list .el-cascader-node__label,
.el-picker-panel__content,
.el-date-picker__header .el-date-picker__header-label,
.el-picker-panel__content .el-date-table th,
.el-autocomplete-suggestion__wrap .el-autocomplete-suggestion__list li {
  color: #000;
}

.el-table {
  .el-table__cell {
    .cell {
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: normal;
      &:has(img) {
        text-overflow: unset !important;
      }
    }
  }
}

// el-cascader
.el-cascader__suggestion-panel {
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
  .el-cascader__suggestion-list {
    box-sizing: border-box;
    color: #000;
    .el-cascader__suggestion-item {
      height: auto;
      min-height: 2.5em;
    }
  }
}

// el-date
.el-range-separator {
  width: 8% !important;
}
.el-date-editor--datetime .el-icon-time,
.el-date-editor--datetimerange .el-icon-time,
.el-date-editor--date .el-icon-date {
  color: $--icon-color !important;
  &::before {
    content: '\e666';
  }
}
.el-date-editor--time .el-icon-time,
.el-date-editor--timerange .el-icon-time {
  color: $--icon-color !important;
  &::before {
    content: '\e674';
  }
}
.el-date-table td.in-range div,
.el-month-table td.in-range div {
  background-color: #ffead1 !important;
  &:hover {
    background-color: #ffead1 !important;
  }
}

// el-checkbox  el-radio
.el-checkbox__label,
.el-radio__label {
  color: $--color-text-primary;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word !important;
}

// el-input  el-textarea
.el-input__inner,
.el-textarea__inner {
  @extend body;
}

.el-input__inner .el-range-input {
  color: $--color-text-primary !important;
}

.el-table__cell {
  .el-radio__inner {
    border-radius: 3px;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #fff !important;
      &::after {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: $--color-primary;
      }
    }
  }
}

.radio-no-label.el-radio .el-radio__label {
  display: none;
}

.el-date-picker.curstom-data-popup.has-sidebar.has-time {
  width: 322px;

  .el-picker-panel__body {
    margin-left: 0;
  }

  .el-picker-panel__sidebar {
    top: auto;
    bottom: -39px;
    padding: 4px;
    background-color: #ffffff;
    width: 100%;
    z-index: 9;
    box-sizing: border-box;
    text-align: right;

    .el-picker-panel__shortcut {
      width: auto;
      display: inline-block;
      white-space: nowrap;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #e6e6ee;
      border-color: #dbdbe4;
      color: #575777;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      padding: 4px;
      height: auto;
      line-height: 1;
      border-radius: 3px;
    }
  }
}

// el-upload
.is-disabled {
  + .el-upload {
    cursor: not-allowed;
  }
}

// el-image
.image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// inline-flex
.inline-flex-align-center {
  display: inline-flex;
  align-items: center;
}

// el-timeline
.el-timeline-item__content {
  word-break: normal;
  overflow-wrap: break-word;
  text-align: left;
}

// image
@mixin image-box {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
@mixin image-box-item {
  position: relative;
  .el-image {
    width: 100%;
    height: 100%;
  }
  &:hover {
    .mask {
      opacity: 1;
    }
  }
  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: $--font-size-medium;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    i {
      cursor: pointer;
    }
  }
}

.image_preview_box {
  @include image-box;
  .image_preview_item {
    @include image-box-item;
  }
}
</style>
